import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export function ExternalLink({children, href}) {
  return (
    <Link
      href={href}
      target="__blank"
      rel="noopener"
      sx={{margin:1}}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Box>
          {children}
        </Box>
        <ExitToAppIcon fontSize="small" />
      </Stack>
    </Link>
  )
}
