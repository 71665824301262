import * as React from 'react';
import {useRecoilState} from 'recoil';
import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useParams } from "react-router-dom";
import numeral from 'numeral'
import {ExternalLink} from '../ExternalLink'
import {FavoritesButton} from '../Favorites'
import {StatValue} from '../StatValue'
import {CoinChart} from './CoinChart'
import {CoinPrices} from './CoinPrices'
import {KlineChart} from '../KlineChart'
import {DepthChart} from '../DepthChart'
import {userState} from '../../atoms'
import {HTTP_HOST} from '../../constants'

export function CoinDetail() {
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  const {uuid} = useParams()
  const [graphType, setGraphType] = React.useState("depth")
  const [coin, setCoin] = React.useState({})
  React.useEffect(() => {
    const load = async () => {
      try {
        const response = await fetch(HTTP_HOST + '/api/coins/' + uuid)
        if (response.ok) {
          const json = await response.json()
          if (json.status.toLowerCase() === 'success') {
            setCoin(json.coin)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
    load()
  }, [uuid])
  return (
    <Box m={5}>
      <Grid container direction="row" spacing={5} justifyContent="space-evenly">
        <Grid item >
          <Stack alignItems="center">
            <a
              rel="noopener"
              target="__blank"
              href={coin.websiteUrl}
            >
              <img alt="icon" width={200} src={coin.iconUrl} />
            </a>
          </Stack>
          <StatValue label="Name" value={coin.name} />
          <StatValue label="Symbol" value={coin.symbol} />
          {/**
          <Tooltip title={`Price: $${coin.price}`}>
            <div>
              <StatValue label="Price" value={
                numeral(coin['price']).format('$0,0.000').toUpperCase()
              } />
            </div>
          </Tooltip>
          */}
          <StatValue label="Market Cap" value={
            numeral(coin['marketCap']).format('0.00a').toUpperCase()
          } />
          <StatValue label="24h Volume" value={
            numeral(coin['volume24h']).format('0.00a').toUpperCase()
          } />
          <StatValue label="Rank" value={`#${coin.rank}`} />
          {coin.websiteUrl && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography color="secondary" align="center" variant="h6">Website Url:</Typography>
              <ExternalLink href={coin.websiteUrl}>{coin.websiteUrl}</ExternalLink>
            </Stack>
          )}
          <CoinPrices coin={coin} />
          {/**
          <StatValue
            label="Supply"
            value={`${numeral(coin.supply.circulating).format('0.00a').toUpperCase()}/${numeral(coin.supply.total).format('0.00a').toUpperCase()}`}
          />
          */}
          <Stack>
            {user.token && (
              <FavoritesButton coin={coin} />
            )}
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack alignItems="center" direction="row" justifyContent="end">
            <ToggleButtonGroup
              sx={{margin: 1}}
              exclusive
              value={graphType}
              onChange={(event, newValue) => setGraphType(newValue)}
            >
              <ToggleButton aria-label="left aligned" value="kline">
                <Tooltip title="Candlestick Graph">
                  {graphType === "kline" ? (
                    <img alt="kline chart active" width="20" src="/CandlestickIcon.svg" />
                  ) : (
                    <img alt="kline chart inactive" width="20" src="/CandlestickIconInactive.svg" />
                  )}
                </Tooltip>
              </ToggleButton>
              <ToggleButton aria-label="centered" value="line">
                <Tooltip title="Line Graph">
                  {graphType === "line" ? (
                    <ShowChartIcon sx={{ color: "green" }} />
                  ) : (
                    <ShowChartIcon />
                  )}
                </Tooltip>
              </ToggleButton>
              <ToggleButton aria-label="right aligned" value="depth">
                <Tooltip title="Depth Graph">
                  {graphType === "depth" ? (
                    <img alt="depth chart active" width="20" src="/DepthChartIcon.svg" />
                  ) : (
                    <img alt="depth chart inactive" width="20" src="/DepthChartIconInactive.svg" />
                  )}
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          {coin.id && graphType === "line" && (
            <CoinChart coin={coin} />
          )}
          {coin.id && graphType === "kline" && (
            <KlineChart coin={coin} />
          )}
          {coin.id && graphType === "depth" && (
            <DepthChart coin={coin} />
          )}
          <Divider />
          <div dangerouslySetInnerHTML={{__html: coin.description}} />
        </Grid>
      </Grid>
    </Box>
  )
}
