import {useState} from 'react'
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {Link as RouterLink} from 'react-router-dom';
import {ChangeValue} from '../ChangeValue'
import {TimePeriodSelect} from '../TimePeriodSelect'
import {Candlestick} from './Candlestick'
import numeral from 'numeral'

export function KlineChart({coin}) {
  const [timePeriod, setTimePeriod] = useState('24h')
  return (
    <Box sx={{
      border: '1px solid white',
      background: '#c0bdbd',
      borderRadius: 1
    }}>
      <Paper elevation={5} sx={{padding: 2}}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <Tooltip title={coin.name}>
              <div>
                <Link component={RouterLink} to={`/coin/${coin.id}`}>
                  <Avatar src={coin.iconUrl} />
                </Link>
              </div>
            </Tooltip>
            <Typography variant="caption" color="primary">Price:</Typography>
            <Tooltip title={'$' + coin.price}>
              <Typography variant="h6">{numeral(coin.price).format('$0,00.000', Math.floor)}</Typography>
            </Tooltip>
            <ChangeValue timePeriod={timePeriod} value={coin.change} />
          </Stack>
          <TimePeriodSelect value={timePeriod} onChange={(tp) => {
            setTimePeriod(tp)
          }}/>
        </Stack>
      </Paper>
      <Box sx={{padding: 5, svg: {overflow: 'visible'}}}>
        <Candlestick coin={coin} />
      </Box>
    </Box>
  )
}
