import KUTE from "kute.js";

export const createTween = (from, to) => KUTE.fromTo(
  from, // target shape
  { path: from }, // from shape
  { path: to }, // to shape
  {
    // options
    easing: "easingCubicInOut",
    yoyo: false,
    repeat: 0,
    repeatDelay: 1500,
    delay: 0,
    duration: 2500,
    morphIndex: 127
  }
)
