import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import numeral from 'numeral'
import {HTTP_HOST} from '../../constants';

import {ExternalLink} from '../ExternalLink'

function exchangeUrl(name, coin) {
  if (name === "Binance US") {
    return "https://www.binance.us/en/buy-sell-crypto/" + coin.symbol
  } else if (name === "Crypto.com") {
    return "https://crypto.com/price/" + coin.name.toLowerCase()
  }
  return ""
}

export function CoinPrices({coin}) {
  const [prices, setPrices] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const loadInitialData = async () => {
      const response = await fetch(HTTP_HOST + '/api/coins/' + coin.symbol + '/prices')
      if (response.ok) {
        const json = await response.json()
        setPrices(json)
        setLoading(false)
      }
    }
    if (coin && coin.id) {
      loadInitialData()
    }
  }, [coin])
  return (
    <Paper>
      <Box m={1}>
        <Typography variant="h6" textAlign="center">Prices</Typography>
      </Box>
      <Divider />
      {loading && (
        <CircularProgress />
      )}
      {prices.map((p, idx) => (
        <div key={`coin-price-${idx}`}>
          <ExternalLink href={exchangeUrl(p.exchange.name, coin)}>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{margin:1}}>
            <Box
              sx={{height: 25}}
            ><img alt={`${p.exchange.name} logo`} width="100" style={{margin: 5}} src={p.exchange.iconUrl} /></Box>
            <Typography sx={{margin:1}}>{numeral(p.price).format('$0,0.000').toUpperCase()}</Typography>
            </Stack>
          </ExternalLink>
          <Divider />
        </div>
      ))}
    </Paper>
  )
}
