import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {MD5} from '../../md5'


export function Team() {
  return (
    <div>
      <Typography variant="h2" sx={{margin:2}} textAlign="center">Meet the team</Typography>

      <Stack alignItems="center" direction="row" justifyContent="center" spacing={5}>
        <Paper>
          <Link rel="noopener" target="__blank" href="https://ryanhigdon.com">
            <Typography variant="h6" textAlign="center" sx={{margin:1}}>ryan</Typography>
            <Avatar
              src={"https://www.gravatar.com/avatar/" + MD5("ryan.higdon@gmail.com") + '?d=identicon'}
              sx={{ width: 80, height: 80, margin: 1 }}
            />
          </Link>
        </Paper>
        <Paper>
          <Link href="https://github.com/jhigdon" rel="noopener" target="__blank">
            <Typography variant="h6" textAlign="center" sx={{margin:1}}>jon</Typography>
            <Avatar
              src={"https://www.gravatar.com/avatar/" + MD5("jhigdon@gmail.com") + '?d=identicon'}
              sx={{ width: 80, height: 80, margin: 1 }}
            />
          </Link>
        </Paper>
      </Stack>
    </div>
  )
}
