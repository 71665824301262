import {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import QRCode from "react-qr-code";
import {userState} from '../../atoms';
import {HTTP_HOST} from '../../constants';
import {VerifyTwoFactor} from './VerifyTwoFactor';

export function SetupTwoFactor({onSuccess}) {
  const [user, setUser] = useRecoilState(userState);
  const [uri, setUri] = useState('')
  useEffect(() => {
    const load = async () => {
      const response = await fetch(HTTP_HOST + '/api/otp/provision', {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      })
      if (response.ok) {
        const json = await response.json()
        setUri(json.uri)
        setUser({
          ...user,
          token: json.token,
        })
      }
    }
    load()
    // we don't want to add the user since we are changing the token
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUri])
  return (
    <Stack alignItems="center">
      <Paper elevation={5}>
        <Typography
          sx={{margin: 2}}
          variant="h2">Scan QR Code in your Authenticator App</Typography>
        {uri ? (
          <Stack alignItems="center">
            <Box m={2}>
              <QRCode value={uri} />
            </Box>
            <VerifyTwoFactor
              path="/api/otp/verify"
              onSuccess={(updatedUser) => {
                setUser({
                  ...updatedUser,
                  token: user.token
                })
                onSuccess()
              }}
              token={user.token}
            />
          </Stack>
        ) : (
          <CircularProgress />
        )}
      </Paper>
    </Stack>
  )
}
