import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {Link as BrowserLink} from 'react-router-dom';
export function AdvertiseHere() {
  return (
    <Paper>
      <Typography variant="subtitle1" textAlign="center">Advertise Here</Typography>
      <Divider />
      <Box sx={{minWidth: 250, minHeight: 100}}>
        <Stack sx={{margin:1}} alignItems="center">
          <Link component={BrowserLink} to="/coin/65PHZTpmE55b">
            <img alt="crypto dot com link" width="50" src="https://cdn.coinranking.com/2o91jm73M/cro.svg" />
          </Link>
        </Stack>
      </Box>
    </Paper>
  )
}
