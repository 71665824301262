import * as React from 'react';
import {useRecoilState} from 'recoil';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';
import FlareIcon from '@mui/icons-material/Flare';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
//import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import {useNavigate} from 'react-router-dom';
import {Logo} from '../Logo';
import {Search} from '../Search';
import {AccountMenu} from './AccountMenu';
import {NavTicker} from './NavTicker';
import {marketState, userState} from '../../atoms';

export function Nav({mode, onChange}) {
  // eslint-disable-next-line no-unused-vars
  const [market, setMarket] = useRecoilState(marketState)
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  const [showSearch, setShowSearch] = React.useState(false);
  const navigate = useNavigate()
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Modal open={showSearch} onClose={() => setShowSearch(false)}>
        <div>
          <Search onClose={() => setShowSearch(false)}/>
        </div>
      </Modal>
      <AppBar position="sticky">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Link color="secondary" to="/" component={RouterLink}>
              <Logo market={market} />
            </Link>
          </Box>
          {user?.token.length > 1 && (
            <>
              <Tooltip title="Your dashboards">
                <div>
                  <IconButton onClick={() => navigate("/dashboards")}>
                    <DashboardTwoToneIcon fontSize="large" />
                  </IconButton>
                </div>
              </Tooltip>
              {/**
              <Tooltip title="Your alerts">
                <div>
                  <IconButton onClick={() => navigate("/alerts")}>
                    <NotificationsNoneTwoToneIcon fontSize="large" />
                  </IconButton>
                </div>
              </Tooltip>
              */}
              <Tooltip title="Your favorites">
                <div>
                  <IconButton onClick={() => navigate("/favorites")}>
                    <FavoriteTwoToneIcon fontSize="large" />
                  </IconButton>
                </div>
              </Tooltip>
            </>
          )}
          <Tooltip title="Search something">
            <div>
              <IconButton onClick={() => setShowSearch(true)}>
                <SearchIcon fontSize="large" />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Swap your theme">
            <div>
                {mode === "light" ? (
                  <IconButton onClick={onChange}>
                    <DarkModeIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={onChange}>
                    <FlareIcon/>
                  </IconButton>
                )}
            </div>
          </Tooltip>
          {user.token ? (
            <AccountMenu />
          ) : (
            <Button onClick={() => {
              const path = window.location.pathname
              navigate(`/sign-in?path=${path !== '/sign-in' ? path : '/'}`)
            }} variant="contained" sx={{ my: 1, mx: 1.5 }}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {user.token && false && (
        <NavTicker />
      )}
    </Box>
  )
}
