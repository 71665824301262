import {useRecoilState} from 'recoil';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {userState} from '../../atoms';
import {VerifyTwoFactor} from './VerifyTwoFactor';

export function DisableTwoFactor({onSuccess}) {
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  return (
    <Stack alignItems="center">
      <Paper elevation={5}>
        <Typography
          sx={{margin: 2}}
          variant="h2">Disable Two-Factor Authentication</Typography>
        <Stack alignItems="center">
          <VerifyTwoFactor
            path="/api/otp/disable"
            onSuccess={(updatedUser) => {
              setUser({
                ...updatedUser,
                token: user.token
              })
              onSuccess()
            }}
            token={user.token}
          />
        </Stack>
      </Paper>
    </Stack>
  )
}
