import * as React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link as RouterLink} from 'react-router-dom';

const footers = [
  {
    title: 'Company',
    description: [
      {label:'Team', href: '/team'},
      {label:'History', href: '/history'},
      {label:'Contact Us', href: '/contact-us'},
    ],
  },
  {
    title: 'Features',
    description: [
      {label:'Dashboard', href: '/dashboards'},
      {label:'Favorites', href: '/favorites'},
    ],
  },
  {
    title: 'Resources',
    description: [
      {label:'Pricing', href: '/pricing'},
    ],
  },
  {
    title: 'Legal',
    description: [
      {label: 'Privacy policy', href: '/privacy-policy'},
      {label: 'Terms of use', href: '/terms-of-use'},

    ],
  },
];
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" target="__blank" rel="noopener" href="https://crypto-graph.net/">
        Crypto-Graph.net
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
export function Footer() {
  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [3, 6],
      }}
    >
      <Grid container spacing={4} justifyContent="space-evenly">
        {footers.map((footer) => (
          <Grid item xs={6} sm={3} key={footer.title}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.description.map((item) => (
                <li key={item.label}>
                  <Link component={RouterLink} to={item.href} variant="subtitle1" color="text.secondary">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  )
}
