import NativeSelect from '@mui/material/NativeSelect';
import Tooltip from '@mui/material/Tooltip';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

export function TimePeriodSelect({onChange, value}) {
  return (
    <Tooltip title="Select a time range">
      <NativeSelect
        value={value}
        onChange={e => onChange(e.target.value)}
        IconComponent={AccessTimeFilledIcon}
        inputProps={{
          name: 'Range',
          id: 'uncontrolled-native',
        }}
      >
        {['3h', '24h', '7d', '30d', '3m', '1y', '3y', '5y'].map(s => {
          return (
            <option key={s}>{s}</option>
          )
        })}
      </NativeSelect>
    </Tooltip>
  )
}
