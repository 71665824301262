import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {HTTP_HOST} from '../../constants';

export function VerifyTwoFactor({path, onSuccess, token}) {
  const [error, setError] = useState('')
  const {control, handleSubmit} = useForm()
  const onSubmit = async (data) => {
    setError('')
    const response = await fetch(HTTP_HOST + path, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({code: Object.values(data).join('')})
    })
    if (response.ok) {
      const json = await response.json()
      onSuccess(json)
    } else {
      if (response.status === 403) {
        setError("Invalid/Expired Token")
      } else {
        setError("Invalid Code")
      }

    }
  }
  return (
    <div>
      <Stack alignItems="center">
        <Typography sx={{margin: 1}}>Enter Code</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" spacing={1}>
              {[...new Array(6)].map((_, idx) => (
                <Box key={`verify-two-factor-code-${idx}`}>
                  <Controller
                    name={"code"+idx}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => {

                        return (
                          <TextField
                            sx={{width: 40}}
                            minwidth={1}
                            onChange={(event) => {
                              onChange(event.target.value.slice(0, 1))
                            }}
                            value={value}
                          />
                        )
                    }}
                  />
                </Box>
              ))}
          </Stack>
          <Stack alignItems="center">
            <Button
              type="submit"
              variant="contained"
              sx={{margin:2}}
            >Verify</Button>
            <Grow in={error !== ""} mountOnEnter unmountOnExit>
              <Typography color="error" sx={{margin:1}}>Invalid Code</Typography>
            </Grow>
          </Stack>
        </form>
      </Stack>
    </div>
  )
}
