import {atom} from 'recoil';
import {createUser} from './factory'

export const userState = atom({
  key: "userState",
  default: localStorage.getItem('crypto_graph_auth_token') ?
    JSON.parse(localStorage.getItem('crypto_graph_auth_token'))
  : createUser()
})

export const favoritesState = atom({
  key: "favoritesState",
  default: [],
})
export const coinsState = atom({
  key: "coinsState",
  default: [],
})

export const coinStatsState = atom({
  key: "coinStatsState",
  default: {
    totalCoins: 0,
    total24hVolume: 0,
    totalExchanges: 0,
    totalMarketCap: 0,
    totalMarkets: 0,
  },
})
export const marketState = atom({
  key: "marketState",
  default: {
    isUp: true,
  },
})
export const settingsState = atom({
  key: "settingsState",
  default: {
    primaryColor: '#6D6B41',
    secondaryColor: '#81B34E',
  },
})
export const widgetsState = atom({
  key: "widgetsState",
  default: [],
})
