import {Component, useEffect, useState} from 'react'
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {DateTime} from 'luxon'
import numeral from 'numeral'
import {
  VictoryAxis,
  VictoryChart,
  VictoryCandlestick,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import {HTTP_HOST, WS_HOST} from '../../constants';

class Label extends Component {
  static defaultEvents = VictoryTooltip.defaultEvents;
	render() {
  	return (
      <g>
        <VictoryTooltip
          {...this.props}
          text={`
            Open: ${numeral(this.props.datum.open).format('0.000a').toUpperCase()}
            Close: ${numeral(this.props.datum.close).format('0.000a').toUpperCase()}
            High: ${numeral(this.props.datum.high).format('0.000a').toUpperCase()}
            Low: ${numeral(this.props.datum.low).format('0.000a').toUpperCase()}
          `}
           x={200} y={240}
          orientation="top"
          pointerLength={0}
          cornerRadius={0}
          width={100}
          height={100}
          //flyoutStyle={{ fill: "black" }}
        />
      </g>
    );
  }
}

export function Candlestick({coin, timePeriod}) {
  const [klineData, setKlineData] = useState([])
  const [client, setClient] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const loadInitialKlineData = async () => {
      const response = await fetch(HTTP_HOST + '/api/coins/' + coin.symbol + '/kline')
      if (response.ok) {
        const json = await response.json()
        setKlineData(json.slice(475).map(entry => ({
          x: entry.x,
          open: parseFloat(entry.open),
          close: parseFloat(entry.close),
          high: parseFloat(entry.high),
          low: parseFloat(entry.low),
        })))
        setLoading(false)
      }
    }
    if (coin && coin.id) {
      loadInitialKlineData()
    }

  }, [coin])
  useEffect(() => {
    if (client) {
      client.onopen = () => {};
      client.onmessage = (message) => {
        const json = JSON.parse(message.data)
        const entry = {
          x: json.x,
          open: parseFloat(json.open),
          close: parseFloat(json.close),
          high: parseFloat(json.high),
          low: parseFloat(json.low),
        }
        setKlineData([...klineData, entry])
      };
      client.onerror = (error) => {
        console.error(error)
      }
    }
    return () => {
      if (client) client.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (client) {
      client.close()
    }
    if (!loading) {
      setClient(new W3CWebSocket(`ws:/${WS_HOST}/ws/kline?symbol=${coin.symbol}&uuid=${coin.id}&timePeriod=${timePeriod}`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, timePeriod])
  return (
    <div>
      <VictoryChart
        domainPadding={{ y: 10 }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${
              DateTime.fromJSDate(new Date(datum.x)).toFormat('F')
            }
            $${datum.y} `}
          />
        }
      >
        <VictoryAxis
          tickFormat={(y) => {
              return (`${DateTime.fromJSDate(new Date(y)).toFormat('ha')}`)
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(x) => {
            return (`$${numeral(x).format('0.00a', Math.floor)}`)
          }}
        />
        {klineData.length > 0 && (
          <VictoryCandlestick
            lowLabels
            lowLabelComponent={<Label pointerLength={0}/>}
            events={[{
              target: "data",
              eventHandlers: {
                onMouseOver: () => ({
                  target: "lowLabels", mutation: () => ({ active: true })
                }),
                onMouseOut: () => ({
                  target: "lowLabels", mutation: () => ({ active: false })
                })
              }
            }]}
            candleColors={{ positive: "green", negative: "red" }}
            style={{
              data: { stroke: coin.color }
            }}
            data={klineData}
          />
        )}
      </VictoryChart>
    </div>
  )
}
