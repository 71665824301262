import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react'
import {DateTime} from 'luxon'
import numeral from 'numeral'
import { VictoryAxis, VictoryChart, VictoryLine, VictoryVoronoiContainer } from 'victory';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {Link as RouterLink} from 'react-router-dom';
import {ChangeValue} from '../ChangeValue'
import {TimePeriodSelect} from '../TimePeriodSelect'
import {WS_HOST} from '../../constants'


export function CoinChart({coin}) {
  const [price, setPrice] = useState(0.00)
  const [coinHistory, setCoinHistory] = useState([])
  const [change, setChange] = useState("0.00")
  const [timePeriod, setTimePeriod] = useState('24h')
  const [client, setClient] = useState(null)
  useEffect(() => {
    if (client) {
      client.onopen = () => {};
      client.onmessage = (message) => {
        const json = JSON.parse(message.data)
        if (json.status === 'success') {
          setCoinHistory(json.data.history)
          setChange(json.data.change)
          setPrice(json.data.history[json.data.history.length-1].price)
        }
      };
      client.onerror = (error) => {
        console.log(error)
      }
    }
    return () => {
      if (client) client.close()
    }
  }, [client])
  useEffect(() => {
    if (client) {
      client.close()
    }
    setClient(new W3CWebSocket(`ws:/${WS_HOST}/ws/history?uuid=${coin.coinrankingUuid}&timePeriod=${timePeriod}`))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePeriod])
  const data = coinHistory.map((point, idx) => ({
    y: parseFloat(point.price),
    x: new Date(point.timestamp * 1000),
  }))
  return (
    <Box sx={{
      border: '1px solid white',
      background: '#c0bdbd',
      borderRadius: 1
    }}>
      <Paper elevation={5} sx={{padding: 2}}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <Tooltip title={coin.name}>
              <div>
                <Link component={RouterLink} to={`/coin/${coin.id}`}>
                  <Avatar src={coin.iconUrl} />
                </Link>
              </div>
            </Tooltip>
            <Typography variant="caption" color="primary">Price:</Typography>
            <Tooltip title={'$' + price}>
              <Typography variant="h6">{numeral(price).format('$0,00.000', Math.floor)}</Typography>
            </Tooltip>
            <ChangeValue timePeriod={timePeriod} value={change} />
          </Stack>
          <TimePeriodSelect value={timePeriod} onChange={(tp) => {
            setTimePeriod(tp)
          }}/>
        </Stack>
      </Paper>
      <Box sx={{padding: 5, svg: {overflow: 'visible'}}}>
        <VictoryChart
          domainPadding={{ y: 10 }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => `${
                DateTime.fromJSDate(new Date(datum.x)).toFormat('F')
              }
              $${datum.y} `}
            />
          }
        >
          <VictoryAxis
            tickFormat={(y) => {
                return (`${DateTime.fromJSDate(new Date(y)).toFormat('ha')}`)
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(x) => {
              return (`$${numeral(x).format('0.00a', Math.floor)}`)
            }}
          />
          <VictoryLine
            style={{
              data: { stroke: coin.color }
            }}
            data={data}
            x="x"
            y="y"
          />
        </VictoryChart>
      </Box>
    </Box>
  )
}
