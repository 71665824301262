import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export function History() {
  return (
    <div>
      <Typography variant="h2" sx={{margin:2}} textAlign="center">Our History</Typography>
      <Stack alignItems="center" direction="row" justifyContent="center" spacing={5}>
        <Container maxWidth="sm">
          <Paper>
              <Typography variant="body2" textAlign="center" sx={{margin:1, padding: 1}}>
                Crypto-Graph.net started as a hobby project of two brothers seeking
                to properly invest and learn in and about Blockchain.  It soon became
                apparent, that in order to keep track of the vast ecosystem, that we
                needed to build a tool for the every day crypto investor.
              </Typography>
          </Paper>
          <Paper>
              <Typography variant="body2" textAlign="center" sx={{margin:1, padding: 1}}>
                Today the project has involved into a complex backend system that reads
                directly from todays most popular crypto exchange markets.  You get real-time
                pricing across multiple exchanges -- so you _know_ you are getting the best
                price for your investment.
              </Typography>
          </Paper>
        </Container>
      </Stack>
    </div>
  )
}
