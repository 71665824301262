import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export function ContactUs() {
  return (
    <div>
      <Typography variant="h2" sx={{margin:2}} textAlign="center">Contact Us</Typography>
      <Stack alignItems="center" direction="row" justifyContent="center" spacing={5}>
        <Container maxWidth="sm">
          <Paper>
              <Typography variant="body2" textAlign="center" sx={{margin:1, padding: 1}}>
                Drop us a line at admin (at) crypto-graph.net
              </Typography>
          </Paper>
        </Container>
      </Stack>
    </div>
  )
}
