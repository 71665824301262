import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useEffect, useRef, useState} from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  Hint,
  Crosshair,
  AreaSeries,
  LineSeries,
} from 'react-vis';

export function Chart({coin, asks, bids}) {
  const ref = useRef(null)
  const [chartWidth, setChartWidth] = useState(300)
  const [chartHeight, setChartHeight] = useState(500)
  const [asksHint, setAsksHint] = useState(null)
  const [bidsHint, setBidsHint] = useState(null)
  useEffect(() => {
    if (ref.current) {
      setChartWidth(Math.floor(ref.current.clientWidth / 2))
      setChartHeight(ref.current.clientHeight)
    }
  }, [ref])
  return (
    <div ref={ref}>
      <Stack direction="row">
        <XYPlot
          margin={{left: 10, right: 0, top: 10, bottom: 40}}
          width={chartWidth}
          height={chartHeight}
          onMouseLeave={() => {
            setBidsHint(null)
          }}
        >
          <AreaSeries
            onNearestX={(val) => {
              setBidsHint(val)
            }}
            onSeriesMouseOut={() => setBidsHint(null)}
            color="#0b2c0b"
            data={bids}
          />
          <LineSeries
            color="green"
            data={bids}
          />
          <XAxis />
          <YAxis />
          {bidsHint && (
            <Crosshair values={[bidsHint]}>
              <div />
            </Crosshair>
          )}
          {bidsHint && (
            <Hint value={bidsHint}>
              <Paper>
                <Typography sx={{margin: 2}}>X: {bidsHint ? bidsHint.x : 0}</Typography>
                <Typography sx={{margin: 2}}>Y: {bidsHint ? bidsHint.y : 0}</Typography>
              </Paper>
            </Hint>
          )}
        </XYPlot>
        <XYPlot
          width={chartWidth}
          height={chartHeight}
          margin={{left: 0, right: 10, top: 10, bottom: 40}}
          onMouseLeave={() => setAsksHint(null)}
        >
          <AreaSeries
            onSeriesMouseOver={(v) => {
              setAsksHint(null)
            }}
            onNearestX={(val) => {
              setAsksHint(val)
            }}
            color="#7a2121"
            onSeriesMouseOut={() => {
              setAsksHint(null)
            }}
            data={asks}
          />
          <LineSeries
            color="red"
            data={asks}
          />
          {asksHint && (
            <Crosshair values={[asksHint]}>
              <div />
            </Crosshair>
          )}
          {asksHint ? (
            <Hint value={asksHint}>
              <Paper>
                <Typography sx={{margin: 2}}>X: {asksHint ? asksHint.x : 0}</Typography>
                <Typography sx={{margin: 2}}>Y: {asksHint ? asksHint.y : 0}</Typography>
              </Paper>
            </Hint>
          ) : null}
          <XAxis />
          <YAxis orientation="right" />
        </XYPlot>
      </Stack>
    </div>
  )
}
