import {useState} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { VictoryChart, VictoryLine } from 'victory';
const DATA = [
  { a: new Date(1982, 1, 1), b: 125 },
  { a: new Date(1987, 1, 1), b: 257 },
  { a: new Date(1993, 1, 1), b: 345 },
  { a: new Date(1997, 1, 1), b: 515 },
  { a: new Date(2001, 1, 1), b: 132 },
  { a: new Date(2005, 1, 1), b: 305 },
  { a: new Date(2011, 1, 1), b: 270 },
  { a: new Date(2015, 1, 1), b: 470 }
]

export function LineGraphExample({selected}) {
  const [isHover, setIsHover] = useState(false)
  return (
    <Box
      sx={{
        border: t => {
          return selected ? '1px solid white' : isHover ? '2px dotted white' : ''
        },
        backgroundColor: t => {
          return t.palette.background.default
        },
        width: 250
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <VictoryChart>
        <VictoryLine
          style={{
            data: { stroke: "pink" }
          }}
          data={DATA}
          x="a"
          y="b"
        />
      </VictoryChart>
      <Typography textAlign="center">Line Graph</Typography>
    </Box>
  )
}
