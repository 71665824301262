import {useState} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {useNavigate} from 'react-router-dom';

export function UpsellDashboards() {
  const navigate = useNavigate()
  const [isHover, setIsHover] = useState(false)
  return (
    <Box
      sx={{
        border: t => {
          return isHover ? '2px dotted white' : ''
        },
        backgroundColor: t => {
          return t.palette.background.default
        },
        width: 250,
        height: 200,
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Stack alignItems="center" spacing={2}>
        <Box m={3}>
          <LockOpenTwoToneIcon fontSize="large"/>
        </Box>
        <Box sx={{padding: 2}}>
          <Button
            onClick={() => navigate('/pricing')}
            variant="outlined"
          >Upgrade to unlock additional Graphs</Button>
        </Box>
      </Stack>
    </Box>
  )
}
