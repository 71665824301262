import * as React from 'react'
import {useRecoilState} from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {settingsState, userState} from '../../atoms'
import {ColorPicker} from './ColorPicker';
import {SetupTwoFactor} from './SetupTwoFactor';
import {DisableTwoFactor} from './DisableTwoFactor';

export function Settings() {
  // eslint-disable-next-line no-unused-vars
  const [settings, setSettings] = useRecoilState(settingsState);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  const [show2FAModal, setShow2FAModal] = React.useState(false)
  React.useEffect(() => {
    //Go get mah settings
  }, [])
  return (
    <Slide in={true} mountOnEnter unmountOnExit direction="right">
      <div style={{margin: 5}}>
        <Modal open={show2FAModal} onClose={()=>setShow2FAModal(false)}>
          <Stack>
            <Stack alignItems="end">
              <Button
                onClick={() => setShow2FAModal(false)}
                sx={{margin:1}} variant="contained">Esc</Button>
            </Stack>
            {user.otp ? (
              <DisableTwoFactor onSuccess={() => setShow2FAModal(false)}/>
            ) : (
              <SetupTwoFactor onSuccess={() => setShow2FAModal(false)}/>
            )}
          </Stack>
        </Modal>
        <Typography textAlign="center" variant="h3">User Settings:</Typography>
        <Divider>
          <Typography variant="h4">Theme Colors:</Typography>
        </Divider>
        <Stack direction="row" spacing={5} alignItems="center" justifyContent="center">
          <Stack alignItems="center" justifyContent="center" direction="row">
            <Typography textAlign="center" variant="subtitle1">Primary:</Typography>
            <Box>
              <ColorPicker
                color={settings.primaryColor}
                onChange={(hex) => setSettings({
                  ...settings,
                  primaryColor: hex,
                })}
              />
            </Box>
          </Stack>
          <Stack alignItems="center" direction="row">
            <Typography variant="subtitle1">Secondary:</Typography>
            <ColorPicker
              color={settings.secondaryColor}
              onChange={(hex) => setSettings({
                ...settings,
                secondaryColor: hex,
              })}
            />
          </Stack>
        </Stack>
        <Divider>
          <Typography variant="h4">Two-Factor Authentication:</Typography>
        </Divider>
        <Stack alignItems="center">
          <FormGroup>
            <FormControlLabel
              control={(
                <Switch
                  checked={user.otp}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={(event) => {
                    if (event.target.value === "on") {
                      setShow2FAModal(true)
                    }
                  }}
                />
              )}
              label={`${user.otp ? "On" : "Off"}`}
            />
          </FormGroup>
        </Stack>
      </div>
    </Slide>
  )
}
