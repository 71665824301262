import * as React from 'react';
import {useRecoilState} from 'recoil';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {CoinCard} from './CoinCard';
import {coinsState} from '../../atoms'

export function CoinList() {
  // eslint-disable-next-line no-unused-vars
  const [coins, setCoins] = useRecoilState(coinsState);
  return (
    <Slide direction="down" in={true} mountOnEnter unmountOnExit>
      <Stack spacing={1} alignItems="center">
        <Divider flexItem={true} />
        {coins.length < 1 && (
          <Typography
            textAlign="center"
            sx={{margin: 3}}
          >We currently don't have any coins.. that's odd.</Typography>
        )}
        <Container maxWidth="xl">
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            {coins.length > 0 && coins.map((favorite, idx) => {
              return (
                <Grid key={`coins-entry-${idx}`} item>
                  <Grow in={true} timeout={(100 * (idx + 1)) + 2000}>
                    <div>
                      <CoinCard idx={idx} coin={coins[idx]} />
                    </div>
                  </Grow>
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </Stack>
    </Slide>
  )
}
