import * as React from 'react';
import {useRecoilState} from 'recoil';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {CoinCard} from '../CoinCard';
import {favoritesState} from '../../atoms'

export function Favorites() {
  // eslint-disable-next-line no-unused-vars
  const [favorites, setFavorites] = useRecoilState(favoritesState);
  return (
    <Slide direction="down" in={true} mountOnEnter unmountOnExit>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h2" textAlign="Center">Favorites</Typography>
        <Divider flexItem={true} />
        {favorites.length < 1 && (
          <Typography
            textAlign="center"
            sx={{margin: 3}}
          >You don't have any favorites</Typography>
        )}
        <Container>
          <Stack spacing={1}>
            {favorites.map((favorite, idx) => {
              return (
                <div key={`favorite-entry-${idx}`}>
                  <CoinCard coin={favorite.coin} />
                </div>
              )
            })}
          </Stack>
        </Container>
      </Stack>
    </Slide>
  )
}
