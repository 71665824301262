import {useState} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { VictoryChart, VictoryCandlestick } from 'victory';
const DATA = [
  {x: new Date(2021, 12, 1), open: 5, close: 10, high: 15, low: 0},
  {x: new Date(2021, 12, 2), open: 10, close: 15, high: 20, low: 5},
  {x: new Date(2021, 12, 3), open: 15, close: 20, high: 22, low: 10},
  {x: new Date(2021, 12, 4), open: 20, close: 10, high: 25, low: 7},
  {x: new Date(2021, 12, 5), open: 10, close: 8, high: 15, low: 5}
]


export function KlineGraphExample({selected}) {
  const [isHover, setIsHover] = useState(false)
  return (
    <Box
      sx={{
        border: t => {
          return selected ? '1px solid white' : isHover ? '2px dotted white' : ''
        },
        backgroundColor: t => {
          return t.palette.background.default
        },
        width: 250
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <VictoryChart>
        <VictoryCandlestick
          candleColors={{ positive: "green", negative: "red" }}
          data={DATA}
          x="a"
          y="b"
        />
      </VictoryChart>
      <Typography textAlign="center">Candlestick Graph</Typography>
    </Box>
  )
}
