import Typography from '@mui/material/Typography';
import { LineChart, Line, Tooltip, XAxis, YAxis } from 'recharts';
import numeral from 'numeral'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{background: 'black'}}>
        <p className="label">{`${label} : $${numeral(payload[0].value).format('0.0a')}`}</p>
        <Typography>{payload[0].payload.name}</Typography>
      </div>
    );
  }

  return null;
};


export function SparkChart({coin}) {
  const stroke = coin.change < 0 ? 'red' : 'green';
  return (
    <LineChart
      width={150}
      height={75}
      data={coin.sparkline.map((value, lineIdx) => ({
        time: lineIdx + 'h',
        name: coin.name,
        price: value,
      }))}
    >
      <Tooltip content={<CustomTooltip />}/>
      <XAxis dataKey="time" hide/>
      <YAxis domain={['dataMin', 'dataMax']} hide/>
      <Line dot={false} type="monotone" dataKey="price" stroke={stroke} />
    </LineChart>
  )
}
