import * as React from 'react'
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

export function StatValue({description, label, value}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={5}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography color="secondary" align="center" variant="h6">{label}</Typography>
        {description && (
          <Tooltip title={description}>
            <InfoTwoToneIcon sx={{fontSize: 16}} />
          </Tooltip>
        )}
      </Stack>
      <Divider />
      <Typography align="center" variant="body2">
        {value}
      </Typography>
    </Stack>
  )
}
