import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import numeral from 'numeral';

export function ChangeValue({timePeriod, value}) {
  const isNegative = value < 0;
  return (
    <div style={{color: isNegative ? 'red' : 'green'}}>
      <Tooltip title={`Change ${value}% over ${timePeriod ? timePeriod : '24 hours'}`}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h5" component="div">
            {!isNegative?'+':''}{numeral(value).format('0.00').toUpperCase()}%
          </Typography>
          {isNegative ? (<TrendingDownIcon />) : (<TrendingUpIcon />)}
        </Stack>
      </Tooltip>
    </div>
  )
}
