import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Footer} from './components/Footer';
import {Nav} from './components/Nav';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createTheme as createMUITheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, useNavigate } from "react-router-dom";
import {useEffect, useState} from 'react';
import {RecoilRoot, useRecoilState} from 'recoil';
import {coinsState, favoritesState, marketState, userState, settingsState} from './atoms'
import {HTTP_HOST} from './constants';
import {Routes} from './Routes';
import '../node_modules/react-vis/dist/style.css';
import Favicon from 'react-favicon';

function createTheme(primary, secondary, mode="dark") {
  return createMUITheme({
    palette: {
      primary: {
        main: primary
      },
      secondary: {
        main: secondary,
      },
      mode,
    },
  })
}

function StatefulApp() {
  // eslint-disable-next-line no-unused-vars
  const [coins, setCoins] = useRecoilState(coinsState);
  // eslint-disable-next-line no-unused-vars
  const [market, setMarket] = useRecoilState(marketState)
  // eslint-disable-next-line no-unused-vars
  const [favorites, setFavorites] = useRecoilState(favoritesState);
  // eslint-disable-next-line no-unused-vars
  const [settings, setSettings] = useRecoilState(settingsState);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  const darkOS = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches
  const navigate = useNavigate()
  const [mode, setMode] = useState(darkOS ? "dark" : "light")
  const [theme, setTheme] = useState(
    createTheme(settings.primaryColor, settings.secondaryColor, mode)
  )
  useEffect(() => {
    const loadFavorites = async () => {
      try {
        const response = await fetch(HTTP_HOST + '/api/favorites', {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        })
        if (response.ok) {
          const json = await response.json()
          setFavorites(json.favorites)
        } else {
          if (response.status === 403) {
            setUser({id:'', token: '', email: ''})
            navigate('/sign-in')
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user.token) {
      loadFavorites()
    }
  }, [user, navigate, setFavorites, setUser])
  useEffect(() => {
    const loadCoins = async () => {
      const response = await fetch(HTTP_HOST + '/api/coins')
      if (response.ok) {
        const json = await response.json()
        if (json.status.toLowerCase() === "success") {
          setCoins(json.data.coins)
          setMarket({
            ...market,
            isUp: !json.data.coins[1].change.startsWith('-'),
          })
        }
      }
    }
    loadCoins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCoins, setMarket])
  useEffect(() => {
    setTheme(createTheme(settings.primaryColor, settings.secondaryColor, mode))
  }, [settings, mode])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <Favicon url={market.isUp ? "/favicon-green.ico" : "/favicon-red.ico"} />
      <Nav mode={mode} onChange={() => {
        if (mode === "light") {
          setTheme(createTheme(settings.primaryColor, settings.secondaryColor, "dark"))
          setMode("dark")
        } else {
          setTheme(createTheme(settings.primaryColor, settings.secondaryColor, "light"))
          setMode("light")
        }
      }} />
      <Routes />
      <Footer />
    </ThemeProvider>
  )
}

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <StatefulApp />
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
