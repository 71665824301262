import {useEffect, useState} from 'react';
import {HTTP_HOST} from '../../constants';
import {Chart} from './Chart'
import CircularProgress from '@mui/material/CircularProgress';

export function DepthChart({coin}) {
  const [asks, setAsks] = useState([])
  const [bids, setBids] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const loadInitialData = async () => {
      const response = await fetch(HTTP_HOST + '/api/coins/' + coin.symbol + '/depth')
      if (response.ok) {
        const json = await response.json()
        let last = 0
        const asks = json.asks.map((entry) => {
          const ask = {
            x: parseFloat(entry[0]),
            y: last + parseInt(entry[1]),
          };
          last = ask.y
          return ask
        })
        setAsks(asks)
        last = 0
        setBids(json.bids.map(entry => {
          const bid = {
            x: parseFloat(entry[0]),
            y: last + parseInt(entry[1]),
          }
          last = bid.y
          return bid
        }))
        setLoading(false)
      }
    }
    if (coin && coin.id) {
      loadInitialData()
    }
  }, [coin])
  if (loading) return (<CircularProgress />)
  return (
    <div>
      <Chart coin={coin} asks={asks} bids={bids}/>
    </div>
  )
}
