import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import numeral from 'numeral';
import {Link as RouterLink} from 'react-router-dom';
import {ChangeValue} from '../ChangeValue';

export function CoinCard({idx, coin}) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              #{idx + 1}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={coin.symbol}>
              <img alt="icon" width={20} src={coin.iconUrl} />
            </Tooltip>
          </Grid>
          <Grid item>
            <Link to={`/coin/${coin.id}`} component={RouterLink}>
              <Typography variant="h5" component="div">
                {coin.name}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="h5" component="div">
              ${numeral(coin.price).format('0.00a')}
            </Typography>
          </Grid>
          <Grid item>
            <ChangeValue value={coin.change} />
          </Grid>
          <Grid item>
            <Typography color="text.secondary">
              {coin.symbol}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
