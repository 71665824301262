import * as React from 'react';
import {useRecoilState} from 'recoil';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsIcon from '@mui/icons-material/Settings';
import {widgetsState, userState} from '../../atoms'
import {HTTP_HOST} from '../../constants'

export function WidgetMenu({widget}) {
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  // eslint-disable-next-line no-unused-vars
  const [widgets, setWidgets] = useRecoilState(widgetsState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Settings">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => {
          const submit = async() => {
            try {
              const response = await fetch(HTTP_HOST + '/api/widgets/delete', {
                method: "DELETE",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${user.token}`
                },
                body: JSON.stringify({
                  widgetId: widget.id,
                })
              })
              if (response.ok) {
                const json = await response.json()
                setWidgets(json.widgets)
              }
            } catch (error) {
              console.error(error)
            }
          }
          if (widget && widget.id) {
            submit()
          }
        }}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          Delete Forever
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
