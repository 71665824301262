import {Alerts} from './components/Alerts'
import {Checkout} from './components/Checkout'
import {CoinDetail} from './components/CoinDetail'
import {CoinList} from './components/CoinList'
import {ContactUs} from './components/ContactUs'
import {Dashboards} from './components/Dashboards'
import {Documentation} from './components/Documentation'
import {Favorites} from './components/Favorites'
import {Pricing} from './components/Pricing'
import {Settings} from './components/Settings'
import {SignIn} from './components/SignIn'
import {SignUp} from './components/SignUp'
import {Team} from './components/Team'
import {History} from './components/History';
import {PrivacyPolicy} from './components/PrivacyPolicy';
import {TermsOfUse} from './components/TermsOfUse';
import {Routes as ReactRouterRoutes, Route } from "react-router-dom";

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/alerts" element={<Alerts />} />
      <Route path="/coins" element={<CoinList />} />
      <Route path="/dashboards" element={<Dashboards />} />
      <Route path="/documentation" element={<Documentation />} />
      <Route path="/coin/:uuid" element={<CoinDetail />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/favorites" element={<Favorites />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/team" element={<Team />} />
      <Route path="/history" element={<History />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/" element={<CoinList />} />
    </ReactRouterRoutes>
  )
}
