import * as React from 'react';
import {useRecoilState} from 'recoil';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link as RouterLink, useNavigate, useSearchParams} from 'react-router-dom';
import {userState} from '../../atoms'
import {HTTP_HOST} from '../../constants'
import {VerifyTwoFactor} from '../Settings/VerifyTwoFactor'

export function SignIn() {
  const [error, setError] = React.useState("");
  const [otpToken, setOtpToken] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const completeSignIn = user => {
    setUser(user)
    localStorage.setItem('crypto_graph_auth_token', JSON.stringify(user))
    const path = searchParams.get("path")
    navigate(path ? path : '/')
  }
  const handleSubmit = (event) => {
    setError("")
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const submit = async () => {
      try {
        const response = await fetch(HTTP_HOST + '/api/user/sign-in', {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body : JSON.stringify({
            email: data.get('email'),
            password: data.get('password'),
          })
        })
        if (response.ok) {
          const json = await response.json()
          if (json.needsVerify) {
            setOtpToken(json.token)
          } else {
            completeSignIn(json)
          }
        } else {
          setError("Failed Login Attempt")
        }
      } catch (error) {
        setError("Uh Oh... Something really bad happened!1!")
        console.error(error)
      }
    }
    submit()
  };
  return (
    <Container component="main" maxWidth="xs">
      <Modal open={otpToken.length > 0} onClose={() => setOtpToken('')}>
        <Container>
          <Paper elevation={5}>
            <Typography
              textAlign="center"
              sx={{paddingTop: 2, marginTop: 15}}
              variant="h3"
            >Authenticate Two-Factor</Typography>
            <VerifyTwoFactor
              onSuccess={(signedInUser) => {
                completeSignIn(signedInUser)
              }}
              path="/api/user/otp"
              token={otpToken}
            />
          </Paper>
        </Container>
      </Modal>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Tooltip title="It's going to be a hoot!">
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <img alt="owl" src="/Owl.svg" />
          </Avatar>
        </Tooltip>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grow in={error !== ""} mountOnEnter unmountOnExit>
            <Typography color="error" textAlign="center">{error}</Typography>
          </Grow>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/sign-up" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
