import * as React from 'react';
import {useRecoilState} from 'recoil';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grow from '@mui/material/Grow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {HTTP_HOST} from '../../../constants'
import {KlineGraphExample} from './KlineGraphExample'
import {LineGraphExample} from './LineGraphExample'
import {UpsellDashboards} from './UpsellDashboards'
import {userState, widgetsState} from '../../../atoms'

function graphTypeToLabel(graphType) {
  switch(graphType) {
    case "line":
      return "Line Graph"
    case "kline":
      return "Candlestick Graph"
    case "depth":
      return "Depth Graph"
    default:
      return ""
  }
}

export function DashboardForm({favorites, onSubmit}) {
  const [coinUuid, setCoinUuid] = React.useState(favorites[0].coin.id)
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  // eslint-disable-next-line no-unused-vars
  const [widgets, setWidgets] = useRecoilState(widgetsState);
  const [graphType, setGraphType] = React.useState("line")
  const handleSubmit = async () => {
    const response = await fetch(HTTP_HOST + '/api/widgets/create',{
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${user.token}`
      },
      body: JSON.stringify({
        coinId: coinUuid,
        userId: user.id,
        widgetType: graphType,
      })
    })
    if (response.ok) {
      const json = await response.json()
      setWidgets(json.widgets)
      onSubmit()
    }
  }
  return (
    <Container>
      <Paper>
        <Typography sx={{padding:5}} textAlign="center" variant="h4">Add Graph</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select a Favorite</InputLabel>
          {favorites.length > 0 && (
            <Select
              sx={{margin: 1}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Pick a Favorite"
              value={coinUuid}
              onChange={(event) => {
                setCoinUuid(event.target.value)
              }}
            >
              {favorites.map((favorite, idx) => {
                return (
                  <MenuItem
                    key={`dashboard-form-favorites-${favorite.id}`}
                    value={favorite.coin.id}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar src={favorite.coin.iconUrl} />
                      <Typography>{favorite.coin.name}</Typography>
                    </Stack>
                  </MenuItem>
                )
              })}
            </Select>
          )}
          <Stack direction="row" justifyContent="space-between" sx={{margin:1}}>
            <Typography sx={{padding: 2}} variant="h6" gutterBottom>Select Graph Type:</Typography>
            <Grow in={true} mountOnEnter unmountOnExit>
              <Typography sx={{padding: 2}} variant="h5" gutterBottom color="secondary">
                {graphTypeToLabel(graphType)}
              </Typography>
            </Grow>
          </Stack>
          <Stack direction="row" spacing={2} sx={{margin: 3}}>
            <Box onClick={() => setGraphType("line")}>
              <LineGraphExample selected={graphType === "line"} />
            </Box>
            <Box onClick={() => setGraphType("kline")}>
              <KlineGraphExample selected={graphType === "kline"} />
            </Box>
            <UpsellDashboards />
          </Stack>
          <Box m={5}>
            <Button
              onClick={handleSubmit}
              variant="contained" fullWidth>Add Graph</Button>
          </Box>
        </FormControl>
      </Paper>
    </Container>
  )
}
