import IconButton from '@mui/material/IconButton';
import {useRecoilState} from 'recoil';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import {favoritesState, userState} from '../../atoms'
import {HTTP_HOST} from '../../constants'

export function FavoritesButton({coin, onClick}) {
  // eslint-disable-next-line no-unused-vars
  const [favorites, setFavorites] = useRecoilState(favoritesState);
  const favorite = favorites.find(f => coin.id === f.coin.id)
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  return (
    <>
      {!favorite ? (
        <IconButton onClick={() => {
          const submit = async() => {
            try {
              const response = await fetch(HTTP_HOST + '/api/favorites/create', {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${user.token}`
                },
                body: JSON.stringify({
                  coinId: coin.id,
                  userId: user.id,
                })
              })
              if (response.ok) {
                const json = await response.json()
                setFavorites(json.favorites)
              }
            } catch (error) {
              console.error(error)
            }

        }
        if (coin && coin.id) {
          submit()
        }
      }}>
          <FavoriteBorderOutlinedIcon />
        </IconButton>
      ) : (
        <IconButton onClick={() => {
          const submit = async() => {
            try {
              const response = await fetch(HTTP_HOST + '/api/favorites/delete', {
                method: "DELETE",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${user.token}`
                },
                body: JSON.stringify({
                  favoriteId: favorite.id,
                })
              })
              if (response.ok) {
                const json = await response.json()
                setFavorites(json.favorites)
              }
            } catch (error) {
              console.error(error)
            }

          }
          if (coin && coin.id) {
            submit()
          }
        }}>
          <FavoriteTwoToneIcon />
        </IconButton>
      )}
    </>
  )
}
