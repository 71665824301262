import {useEffect, useState} from 'react'
import {useRecoilState} from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {CoinChart} from '../CoinDetail/CoinChart'
import {KlineChart} from '../KlineChart'
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import BoltIcon from '@mui/icons-material/Bolt';
import {DashboardForm} from './DashboardForm'
import {WidgetMenu} from './WidgetMenu'
import {useNavigate} from 'react-router-dom';
import {favoritesState, userState, widgetsState} from '../../atoms'
import {HTTP_HOST} from '../../constants'
import {AdvertiseHere} from '../AdvertiseHere'

const LIMIT = 3

function AddButton({onClick}) {
  return (
    <Paper>
      <Box p={16.75} sx={{border: '1px solid grey'}}>
        <Stack alignItems="center">
          <Button
            onClick={onClick}
            sx={{fontSize: 16, width: 300, height: 200}}
            startIcon={<DashboardCustomizeTwoToneIcon />}
          >
            Add Dashboard Widget
          </Button>
        </Stack>
      </Box>
    </Paper>
  )
}

function UpsellButton () {
  const navigate = useNavigate()
  return (
    <Paper>
      <Box p={16.75} sx={{border: '1px solid grey'}}>
        <Stack justifyContent="center" alignItems="center">
          <Button
            onClick={() => navigate('/pricing')}
            sx={{fontSize: 12, width: 200, height: 200}}
            startIcon={<BoltIcon size="large" />}
          >
            Upgrade to unlock additional dashboards
          </Button>
        </Stack>
      </Box>
    </Paper>
  )
}

export function Dashboards() {
  // eslint-disable-next-line no-unused-vars
  const [favorites, setFavorites] = useRecoilState(favoritesState);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  // eslint-disable-next-line no-unused-vars
  const [widgets, setWidgets] = useRecoilState(widgetsState);
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    const loadWidgets = async () => {
      const response = await fetch(HTTP_HOST + '/api/widgets', {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      })
      if (response.ok) {
        const json = await response.json()
        setWidgets(json.widgets)
      }
    }
    if (user.token) {
      loadWidgets()
    }
  }, [user, setWidgets])
  return (
    <Slide in={true} direction="up" mountOnEnter unmountOnExit>
      <div>
        <Modal open={showAddModal} onClose={() => {setShowAddModal(false)}}>
          <Slide in={showAddModal} mountOnEnter unmountOnExit direction="down">
            <div style={{margin: 15}}>
              <DashboardForm
                favorites={favorites}
                onSubmit={() => setShowAddModal(false)}
              />
            </div>
          </Slide>
        </Modal>
        <Typography variant="h2" textAlign="center">Dashboard</Typography>
        <Box m={2}>
          <Grid container spacing={1}>
            {widgets.map((widget, idx) => {
              const coin = widget.coin
              return (
                <Grid key={widget.id} item xs={6}>
                  {coin && (
                    <Stack>
                      <Paper elevation={5}>
                        <Stack alignItems="center" justifyContent="space-between" direction="row">
                          <Typography sx={{margin:1}}>{coin.symbol} {widget.widgetType === "line" ? "Line" : "Candlestick"} Chart</Typography>
                          <WidgetMenu widget={widget} />
                        </Stack>
                      </Paper>
                      {widget.widgetType === "line" && (
                        <CoinChart coin={coin}/>
                      )}
                      {widget.widgetType === "kline" && (
                        <KlineChart coin={coin}/>
                      )}
                    </Stack>
                  )}
                </Grid>
              )
            })}
            {widgets.length >= LIMIT ? (
              <Grid item xs={6}>
                <UpsellButton />
              </Grid>
            ) : (
              <>
                {favorites.length > 0 ? (
                  <Grid item xs={6}>
                    <AddButton onClick={() => setShowAddModal(true)}/>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Stack alignItems="center">
                      <Typography>
                        You need some favorites before you can create widgets.
                      </Typography>
                      <Typography variant="subtitle2" sx={{margin:1}}>
                        Might I suggest:
                        <AdvertiseHere />
                      </Typography>
                    </Stack>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>
      </div>
    </Slide>
  )
}
