import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export function PrivacyPolicy() {
  return (
    <Container maxWidth="lg">
      <Paper>
        <Container maxWidth="md">
          <Typography variant="h1">Privacy Policy</Typography>
          <Typography>
            Crypto Graph "the App” provides Block-chain Analysis "the Service" to users. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App.
          </Typography>

          <Typography variant="h3">Personal Information the App Collects</Typography>
          <Typography>
            Additionally, we collect the following types of personal information from you and/or your customers once you have installed the App:
            Information about you and others who may access the App on behalf of your store, such as your name, address, email address, phone number, and billing information;
            Information about individuals who visit your store, such as their IP address, web browser details, time zone, and information about the cookies installed on the particular device.
            We collect personal information directly from the relevant individual or using the following technologies: “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
          </Typography>

          <Typography variant="h3">How Do We Use Your Personal Information?</Typography>
          <Typography>
            We use the personal information we collect from you and your customers in order to provide the Service and to operate the App.
          </Typography>
          <Typography>
            Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services.
          </Typography>

          <Typography variant="h3">Sharing Your Personal Information</Typography>

          <Typography gutterBottom>We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</Typography>

          <Typography gutterBottom>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</Typography>

          <Typography gutterBottom>Data Retention When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</Typography>

          <Typography gutterBottom>Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</Typography>

          <Typography gutterBottom>Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at admin at crypto-graph.net</Typography>
        </Container>
      </Paper>
    </Container>
  )
}
