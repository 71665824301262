import {useEffect, useMemo, useState} from 'react'
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom'
import {HTTP_HOST} from '../../constants'

export function HelperText() {
  const { focused } = useFormControl() || {};

  const helperText = useMemo(() => {
    if (focused) {
      return 'You must enter at least 3 characters';
    }

    return 'ie. ETH, BTC, Doge';
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}
export function Search({onClose}) {
  const [value, setValue] = useState('')
  const [results, setResults] = useState([])
  useEffect(() => {
    if (value.length > 2) {
      const load = async () => {
        const response = await fetch(HTTP_HOST + '/api/coins/search?query='+value )
        const json = await response.json()
        if (json.status.toLowerCase() === "success") {
          setResults(json.data.coins)
        }
      }
      load()
    } else if (value.length === 0) {
      setResults([])
    }
  }, [value])
  return (
    <Container>
      <Paper sx={{padding: 5}}>
        <Typography textAlign="center" variant="h4">Search</Typography>
        <Box component="form" noValidate autoComplete="off">
          <FormControl sx={{ width: '100%' }}>
            <OutlinedInput
              placeholder="Enter your search string"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <HelperText />
          </FormControl>
          {results.map(result => {
            return (
              <Link key={`search-result-link-${result.id}`} component={RouterLink} to={`/coin/${result.id}`} onClick={() => {
                setResults([])
                setValue('')
                onClose()
              }}>
                <Stack direction="row" justifyContent="space-between">
                  <Avatar src={result.iconUrl} />
                  <div>{result.name}</div>
                  <div>{result.symbol}</div>
                  <div>{result.price}</div>
                </Stack>
              </Link>
            )
          })}
        </Box>
      </Paper>
    </Container>
  );
}
